import * as Yup from 'yup'
import { rangeStep } from 'utils'
import { values } from '@soltalabs/ramda-extra'
import {
  REPAYMENT_TYPE,
  REPAYMENT_FREQUENCY,
  LOAN_AMOUNT_CONFIG,
  LOAN_TERM_CONFIG,
  INTEREST_RATE_CONFIG,
} from '../../constants'

const { object, string, number } = Yup

const loanAmountErrorMessage = `Loan Amount must be between $${LOAN_AMOUNT_CONFIG.MIN} and $${LOAN_AMOUNT_CONFIG.MAX}`

const loanTermErrorMessage = `Loan Term must be between ${LOAN_TERM_CONFIG.MIN} and ${LOAN_TERM_CONFIG.MAX} years`

const interestRateErrorMessage = `Interest Rate must be between ${INTEREST_RATE_CONFIG.MIN}% and ${INTEREST_RATE_CONFIG.MAX}%`

export const validationSchema = object({
  loanAmount: number()
    .required()
    .label('Loan amount')
    .min(LOAN_AMOUNT_CONFIG.MIN, loanAmountErrorMessage)
    .max(LOAN_AMOUNT_CONFIG.MAX, loanAmountErrorMessage)
    .typeError('Loan Amount must be a number'),
  loanTerm: number()
    .required()
    .label('Loan term')
    .oneOf(
      rangeStep(LOAN_TERM_CONFIG.STEP, LOAN_TERM_CONFIG.MIN, LOAN_TERM_CONFIG.MAX + 1),
      loanTermErrorMessage
    )
    .typeError('Loan Term must be a number'),
  interestRate: number()
    .required()
    .label('Interest rate')
    .min(INTEREST_RATE_CONFIG.MIN, interestRateErrorMessage)
    .max(INTEREST_RATE_CONFIG.MAX, interestRateErrorMessage)
    .typeError('Interest Rate must be a number'),
  repaymentFrequency: string()
    .required()
    .oneOf(values(REPAYMENT_FREQUENCY))
    .label('Repayment frequency'),
  repaymentType: string()
    .required()
    .oneOf(values(REPAYMENT_TYPE))
    .label('Repayment type'),
})
