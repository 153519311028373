import React from 'react'
import { Formik as FormProvider } from 'formik'
import { styled, s, smaller } from '../../styled'
import { formatCurrency, formatPercent, makeSliderProps } from 'utils'
import { values } from '@soltalabs/ramda-extra'
import CalculatorBase from './Calculator'
import {
  REPAYMENT_TYPE,
  REPAYMENT_FREQUENCY,
  REPAYMENT_FREQUENCY_OPTIONS,
  REPAYMENT_TYPE_OPTIONS,
  LOAN_AMOUNT_CONFIG,
  INTEREST_RATE_CONFIG,
  LOAN_TERM_CONFIG,
} from '../../constants'

import {
  RangeSlider as RangeSliderBase,
  SelectField as SelectFieldBase,
} from '../components'
import { validationSchema } from './validationSchema'

const Root = styled.div(
  s('flex mx-8 justify-between p-12 flex-column', {
    background: '#fff',
  }),
  smaller('sm')(s('p-4 m-0'))
)
const Title = styled.h2(
  s('m-0 mt-3 font-bold', {
    color: 'var(--text-color)',
  })
)

const Content = styled.div(
  s('mt-6 flex justify-between', { height: 582 }),
  smaller('lg')(s('flex-column h-full'))
)

const Calculator = styled(CalculatorBase)(
  s('flex-1 w-full h-full', { overflow: 'hidden' }),
  smaller('lg')(s('mt-8 ml-0'))
)
const Form = styled.div(
  s('flex flex-column justify-between w-1/4'),
  smaller('lg')(s('w-full'))
)

const RangeSlider = styled(RangeSliderBase)(smaller('lg')(s('mb-5')))
const SelectField = styled(SelectFieldBase)(smaller('lg')(s('mb-3 mt-1')))

const LoanRepaymentForm = () => {
  return (
    <Root>
      <Title>Mortgage calculator</Title>
      <FormProvider
        initialValues={{
          loanAmount: LOAN_AMOUNT_CONFIG.DEFAULT,
          loanTerm: LOAN_TERM_CONFIG.DEFAULT,
          interestRate: INTEREST_RATE_CONFIG.DEFAULT,
          repaymentFrequency: REPAYMENT_FREQUENCY.MONTHLY,
          repaymentType: REPAYMENT_TYPE.PRINCIPAL_AND_INTEREST,
        }}
        validationSchema={validationSchema}
      >
        <Content>
          <Form>
            <RangeSlider
              name="loanAmount"
              label="Loan amount"
              {...makeSliderProps(LOAN_AMOUNT_CONFIG)}
              formatter={formatCurrency}
              labelProps={{ style: s('mt-0') }}
              prefix="$"
            />
            <RangeSlider
              name="loanTerm"
              label="Loan term (years)"
              {...makeSliderProps(LOAN_TERM_CONFIG)}
            />
            <RangeSlider
              name="interestRate"
              label="Interest rate (%)"
              {...makeSliderProps(INTEREST_RATE_CONFIG)}
              formatter={formatPercent}
            />
            <SelectField
              name="repaymentFrequency"
              label="Repayment frequency"
              options={values(REPAYMENT_FREQUENCY_OPTIONS)}
            />
            <SelectField
              name="repaymentType"
              label="Repayment type"
              options={values(REPAYMENT_TYPE_OPTIONS)}
            />
          </Form>
          <Calculator />
        </Content>
      </FormProvider>
    </Root>
  )
}

export default LoanRepaymentForm
