import React from 'react'

import { useFormikContext } from 'formik'

import { pluck, range } from '@soltalabs/ramda-extra'

import { EmailModal, RangeSlider } from '../components'
import { LOAN_TERM_CONFIG, REPAYMENT_FREQUENCY } from '../../constants'
import { styled, s, smaller } from 'styled'
import {
  calculateEligibleIncomeForDebtServicing,
  calculatePeriods,
  calculateRate,
  calculateTotalExpense,
  calculateTotalIncome,
  ensureAccountAmountIsNumber,
  Excel,
  formatCurrency,
  makeSliderProps,
  mapBorrowingCalculatorSummaryData,
} from 'utils'
import { Chart } from './Chart'
import { useEmailModal } from 'hooks/useEmailModal'

const Root = styled.div(s('flex flex-column mt-24 justify-around pb-4'))
const Container = styled.div(
  s('flex justify-between pr-4'),
  smaller('lg')(s('flex-column'))
)
const Summary = styled.div(s('flex flex-column justify-between'))
const ChartContainer = styled.div(
  s('p-12 mr-8 bg-gray-200', { width: '75%' }),
  smaller('lg')(s('w-full m-0 mb-8 px-2'))
)
const Button = styled.div(
  s('w-full px-2 py-2 flex justify-center', {
    background: 'var(--primary-neo-color)',
    color: '#fff',
    borderRadius: 4,
    cursor: 'pointer',
  })
)

const Calculator = (props) => {
  const {
    values: {
      clientIncome,
      hasPartner,
      partnerIncome,
      dependants,
      annualExpenses,
      creditCardLimit,
      carLoanRepayment,
      otherPayment,
      loanTerm,
    },
    values: formValues,
    isValid,
  } = useFormikContext()
  const { isModalOpen, openModal, closeModal } = useEmailModal()

  const incomes = hasPartner ? [clientIncome, partnerIncome] : [clientIncome]

  const totalIncome = calculateTotalIncome(incomes)
  const totalExpenses = calculateTotalExpense(
    parseFloat(dependants),
    parseFloat(annualExpenses),
    ensureAccountAmountIsNumber(creditCardLimit),
    ensureAccountAmountIsNumber(carLoanRepayment),
    ensureAccountAmountIsNumber(otherPayment)
  )

  const eligibleIncome = calculateEligibleIncomeForDebtServicing(
    totalIncome,
    totalExpenses
  )

  const defaultInterestRate = 7.45
  const defaultRepaymentFrequency = REPAYMENT_FREQUENCY.MONTHLY

  const maxLoanAmount = Excel.pv(eligibleIncome, defaultInterestRate)
  const pmt = Excel.pmt(defaultInterestRate / 1200, loanTerm * 12, maxLoanAmount)
  const numOfPeriods = calculatePeriods(loanTerm, defaultRepaymentFrequency)
  const rate = calculateRate(defaultInterestRate, defaultRepaymentFrequency)

  const payments = Excel.consecutivePeriods(
    rate,
    numOfPeriods,
    numOfPeriods,
    maxLoanAmount
  )
  const totalPayableInterest = Excel.totalPayableInterest(
    rate,
    numOfPeriods,
    maxLoanAmount
  )
  const paymentsToVisualise = payments.slice(1)
  const totalAmountOwed = totalPayableInterest + maxLoanAmount
  // must use this instead of the above pmt to avoid marginal error causing the last total to be below 0
  const roundedPmt = totalAmountOwed / numOfPeriods

  const principals = pluck('equity', paymentsToVisualise).map((v) => {
    // return 0 for last period principal (the actual calculation is slightly off and is below 0)
    if (v > -1 && v < 0) return 0
    return Math.abs(v)
  })
  const totals = range(1, numOfPeriods + 1).map((period) => {
    return totalAmountOwed - roundedPmt * period
  })

  return (
    <Root {...props}>
      <Container>
        <ChartContainer>
          {isValid && (
            <>
              <h2>Results</h2>
              <Chart
                totals={totals}
                principals={principals}
                numOfPeriods={numOfPeriods}
                loanTerm={loanTerm}
              />
            </>
          )}
        </ChartContainer>
        <Summary>
          <RangeSlider
            name="loanTerm"
            label="Loan term (years)"
            {...makeSliderProps(LOAN_TERM_CONFIG)}
            containerProps={{ style: s('mb-4') }}
          />
          {isValid && (
            <>
              <div
                style={s('flex h-12', {
                  background: 'var(--primary-neo-color)',
                  color: '#fff',
                  borderRadius: 4,
                })}
              >
                <p style={s('ml-12 flex flex-column justify-center mr-12')}>
                  You’ll be able to borrow around: <br />
                  <span style={s('text-3xl font-semibold mt-2')}>{`${formatCurrency(
                    maxLoanAmount
                  )}`}</span>
                </p>
              </div>

              <div
                style={s('flex bg-gray-100 h-12', {
                  borderRadius: 4,
                })}
              >
                <p style={s('flex flex-column justify-center ml-12')}>
                  Your monthly <br />
                  repayment:
                  <br />
                  <span
                    style={s('text-3xl font-semibold mt-2', {
                      color: 'var(--primary-neo-color)',
                    })}
                  >{`${formatCurrency(Math.abs(pmt))}`}</span>
                </p>
              </div>
            </>
          )}
          <Button onClick={openModal}>Contact us</Button>
        </Summary>
        <EmailModal
          leadInfo={{
            borrowingPowerFormInfo: mapBorrowingCalculatorSummaryData(
              formValues,
              maxLoanAmount,
              pmt
            ),
          }}
          key={isModalOpen}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
      </Container>
    </Root>
  )
}

export default Calculator
